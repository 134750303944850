<template>
  <div class="about-company">
    <div class="about-company__container _container">
      <BreadCrumbs :items="[[$t('main'), '/'], [breadTitle]]" />
      <div class="about-company__body" v-for="(info, i) in about" :key="i">
        <h1 class="about-company__title main-title">{{ info.title }}</h1>
        <div style="display: none">{{ (breadTitle = about[0].title) }}</div>
        <div class="about-company__content" v-if="info.slug != 'cooperation'">
          <div class="about-company__block" v-if="i == 0">
            <div class="about-company__info" v-html="info.description"></div>
            <div class="about-company__image _ibg" v-if="info.slug === 'about'">
              <div class="about-company__arrows">
                <div class="about-company__arrows-left">
                  <img src="../assets/icons/right.png" alt="left" />
                </div>
                <div class="about-company__arrows-right">
                  <img src="../assets/icons/left.png" alt="right" />
                </div>
              </div>
              <swiper
                class="about-company__swiper"
                :breakpoints="breakpoints"
                :navigation="mainNavigation"
                :loop="true"
                :speed="1200"
                :autoplay="{ enabled: true, delay: 1 }"
                :free-mode="true"
              >
                <swiper-slide v-for="(partner, id) in info.additional_images" :key="id">
                  <img class="about-company__img" :src="cdn + partner" alt="" />
                </swiper-slide>
              </swiper>
            </div>            
            <div class="about-company__image1 _ibg"  v-if="info.slug !== 'about'">
              <img :src="cdn + info.image" alt="" />
            </div>
          </div>
          <div class="about-company__block" v-else-if="i == 1">
            <div
              class="about-company__info info2"
              v-html="info.description"
            ></div>
            <div class="about-company__image _ibg img2">
              <img :src="cdn + info.image" alt="" />
            </div>
          </div>
        </div>
        <div
          class="about__partners partners"
          v-else
        >
          <div class="partners__body">
            <h2 class="partners__small-title">
              {{ $t("sendRegion") }}
            </h2>
            <swiper
              v-if="info.additional_images"
              class="partners__swiper"
              :space-between="34"
              :slides-per-view="'auto'"
              :loop="true"
              :speed="1800"
              :autoplay="{ enabled: true, delay: 1 }"
              :free-mode="true"
            >
              <swiper-slide v-for="(partner, id) in info.additional_images" :key="id">
                <img class="partners__img" :src="cdn + partner" alt="" />
              </swiper-slide>
            </swiper>
            <div class="partners__info" v-html="info.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

SwiperCore.use([Autoplay, Navigation]);
export default {
  data() {
    return {
      about: {},
      breadTitle: "",
      mainNavigation: {
        prevEl: ".about-company__arrows-left",
        nextEl: ".about-company__arrows-right",
      },
      breakpoints: {
        0: {
          spaceBetween: 40,
          slidesPerView: 1,
        },
        562: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    BreadCrumbs,
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    async fetchInfo() {
      await axios
        .get(`V1/page-by-slug`, {
          params: {
            slug: this.$route.params.slug,
          },
        })
        .then((response) => {
          this.about = response.data.data;
          setTimeout(() => {
            let tels = document.querySelectorAll('.about-company__info > p > img')
            tels.forEach(item => {
              item.parentElement.style.margin = '20px 0 0 0'
              item.parentElement.style.cursor = 'pointer'
              item.parentElement.onclick = () => {
              window.open(`tel:${item.parentElement.textContent.replace(/[^0-9]/g,"")}`);
            }
            })
          }, 10);
        })

        .catch((error) => {
        });
    },
  },
  mounted() {
    this.fetchInfo();
  },
  watch: {
    "$route.params.slug": function () {
      this.fetchInfo();
    },
  },
};
</script>

<style lang="scss">
.about-company {
  padding: 50px 0 94px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 34px 0;
  }

  &__title {
    margin: 0 0 41px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 32px 0;
    }
  }
  &__arrows {
    display: flex;
    justify-content: right;
    gap: 20px;
    height: 10px;
    margin-bottom: 38px;
    &-left, &-right {
      width: 15px;
      img {
        position: relative;
        width: 15px;
        height: 8px;
      }
    }
  }
  &__swiper {
    width: 100%;
    height: 23vw;
    @media (max-width: $tablet + px) {
      height: 45vw;
    }
    @media (max-width: 562px) {
      height: 90vw;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 38px;
    @media (max-width: $tablet + px) {
      gap: 20px;      
    }
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 38px;
    }
    img:not(:last-child) {
      @media (max-width: $tablet + px) {
        max-height: 75px;
      }
    }
    &:not(:last-child) {
      margin: 0 0 43px 0;

      .about-company__info {
        @media (max-width: $tablet + px) {
          margin: 0 0 55px 0;
        }
      }

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 67px 0;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  &__info {
    @extend .base-text;
    flex: 0 0 auto;
    width: 100%;
    font-weight: 400;
    color: #000000;
    @media (max-width: $tablet + px) {
      width: 100%;
      margin: 0 0 22px 0;
    }
    a {
      text-decoration: underline;
    }
    .redText {
      color: #1ABBE8;
      margin-top: 5px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__image {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    margin: 0 auto;
    @media (max-width: $tablet + px) {
      height: auto;
      width: 100%;
    }
  }

  &__image1 {
    flex: 0 0 auto;
    width: 50%;
    height: 450px;
    margin: 0 auto;
    @media (max-width: $tablet + px) {
      height: 300px;
      width: 100%;
    }
  }

  &__more {
    display: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1ABBE8;
  }

  div,
  p,
  a,
  span {
    text-align: justify;
  }
}

.partners {
  &__small-title {
    @extend .base-text-600;
  }

  &__swiper {
    margin: 49px 0 49px 0;
    .swiper-wrapper {
      transition-timing-function: linear;
    }
    .swiper-slide {
      width: auto;
      transition-timing-function: linear;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 61px 0 61px 0;
    }
  }

  &__img {
    height: 132px;
    img {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
    @media (max-width: $mobileSmall + px) {
      height: 89px;
    }
  }

  &__info {
    @extend .base-text;
    font-weight: 400;
    color: #000000;
    p,
    li {
        margin: 0 0 22px 0;
    }
  }
  &__more {
    margin: 20px 0 0 0;
    @media (min-width: $mobileSmall + px) {
      display: none;
    }
  }
}ol { padding-left: 1.8em; }
p {
  margin: 0;
  padding: 0;
}
.img2 {
  width: 40%;
  max-width: 500px;
  object-fit: cover;
  @media (max-width: $tablet + px) {
    width: 100%;
  }
}
.info2 {
  @media (max-width: $tablet + px) {
    width: 100%;
    margin-top: 20px;
  }
}
.home__main-arrows {

}
</style>
